@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.mobileMenuItem {
  list-style-type: none;
  border-bottom: 1px solid $colour-grey-15;
  border-color: $colour-grey-20;

  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;

  &__link,
  &__button {
    @include display-as('body-m');

    display: block;
    text-decoration: none;
    padding: 11px 24px 11px 16px;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &__tabbedBrandsLink {
      padding: 11px 0;
    }
  }

  &__button {
    @include display-as('heading-xs');

    &__customBackButton {
      text-align: center;
      padding: 13px 0;
      button {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        border-style: none;
        background: transparent;
      }
    }

    &__lastButton {
      margin-bottom: 24px;
    }

    &__nonBoldButton {
      @include display-as('body-m');
    }

    &__mobileNavTest {
      text-align: center;
    }

    background-color: transparent;
    border: 0;
    cursor: pointer;
    width: 100%;
    text-align: left;

    &.offersLabel {
      color: $colour-offers;
    }
  }

  &__back {
    font-weight: $font-weight-regular;
  }

  &__chevron {
    position: relative;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 24px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      height: spacing(2);
      width: spacing(2) + 2;
      margin-top: -10px;
      right: 24px;
      @include svg-background(
        '#{$svgLoadPathAssets}/icons-jb/chevron-right/chevron-right-24px-outlined.svg',
        $colour-black
      );
    }

    &__tabbedBrandsChevron {
      padding-left: 0;
    }
  }

  &__chevronLeft {
    background-color: $colour-grey-5;
    border-color: $colour-grey-20;
    border-bottom: none;
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    cursor: pointer;

    button {
      padding: 13px spacing(2) 13px spacing(5);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        height: spacing(2);
        width: spacing(2) + 2;
        margin-top: -10px;
        left: spacing(1.5);
        @include svg-background(
          '#{$svgLoadPathAssets}/icons-jb/chevron-left/chevron-left-24px-outlined.svg',
          $colour-black
        );
      }
    }
  }

  &__fullWidth {
    margin-left: 0;
    margin-right: 0;
  }

  &__viewMoreLink {
    text-decoration: underline;
  }
}

.offersSubMenu {
  border-top: 1px solid $colour-grey-15;
  list-style: none;
  padding: spacing(0.5) 0;

  li {
    border: 0;
  }
}

.headingSubMenu {
  border-top: none;
  list-style: none;
  padding: 0;

  li {
    margin: 0;
    padding: 0 0 0 16px;
    border-color: $colour-grey-20;
    &:last-child {
      border-bottom: none;
    }
  }
}

.buttonHeading {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-top: 32px;

  h4 {
    @include display-as('heading-xs');
    margin: 0;
    padding: 0;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
