@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.mobileMenuTabs {
  min-height: 48px;
  background-color: $colour-grey-5;
  padding-left: 16px;
  margin-bottom: 12px;
  position: sticky;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 32px;
    height: 100%;

    li {
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 2px solid $colour-grey-5;

      button {
        @include display-as('heading-xs');
        font-weight: 600;
        color: $colour-grey-64;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        outline: none;
        height: 100%;
      }

      &.activeTab {
        border-bottom: 2px solid black;
        button {
          color: black;
        }
      }
    }
  }
}
