@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

input[type='file'] {
  display: none;
}

.imageSearchContainer {
  position: relative;
  visibility: visible;

  &__icon_inside {
    position: absolute;
    top: 0;
    right: 16px;
    width: 48px;
    height: 48px;

    label {
      display: inline;
    }
  }
}

.imageSearchLabel {
  width: 30px !important;
  height: 30px !important;
  position: relative !important;
  p {
    font-size: 32px;
  }

  svg {
    position: relative;
    margin-top: 13px;
    margin-left: 13px;
  }

  &__pointer {
    cursor: pointer;
  }

  &__uploading {
    div {
      width: 28px;
      height: 28px;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
}

.tooltip {
  background-color: $colour-information-10;
  display: flex;
  padding: 12px 12px 12px 16px;
  align-items: center;
  max-width: 227px;
  position: absolute;
  right: 16px;
  top: 70px;
  z-index: 999;
  cursor: pointer;
  visibility: visible;

  &:before {
    content: '';
    position: absolute;
    border-color: transparent transparent $colour-information-10;
    border-style: solid;
    border-width: 0 8px 8px;
    border-top-width: 0;
    right: 16px;
    top: -8px;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    height: 24px;
  }

  span {
    color: $colour-black;
    margin-right: 7px;
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}
