@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

$placeholderHeight: 125px;
$placeholderHeightSmall: 100px;
$compactImage: 68px;
$largeImage: 303px;
$minColumnWidth: 228px;
$contentSpotsImage: 48px;
$contentSpotsXLImage: 96px;

.contentBlock {
  display: flex;
  flex-direction: column;
  min-height: $placeholderHeight;
  text-decoration: none;
  text-align: left;

  .contentBlockImage {
    max-width: 100%;
    min-height: $placeholderHeightSmall;
  }

  .contentBlockImageCompact {
    height: $compactImage;
    width: $compactImage;
  }

  .contentBlockImageContentSpots {
    height: $contentSpotsImage;
    width: $contentSpotsImage;
    min-height: $contentSpotsImage;
  }

  .contentBlockImageContentSpotsXL {
    height: $contentSpotsXLImage;
    width: $contentSpotsXLImage;
    min-height: $contentSpotsXLImage;
  }

  .contentBlockImageLarge {
    max-height: $largeImage;
    max-width: $largeImage;
    width: 100%;
    height: 100%;
    margin: 0 auto 12px;
  }

  .contentBlockImageRounded {
    border-radius: 50%;
  }

  .contentBlockTextCompact {
    height: 68px;
    border-bottom: 1px solid $colour-grey-15;
    margin-left: 10px;
    flex-grow: 1;
    padding-top: 10px;
  }

  .contentBlockText {
    margin-bottom: 0;
  }

  &:hover {
    .contentBlockText {
      text-decoration: underline;
    }
  }

  @include breakpoint(medium) {
    min-width: 270px;
    padding-bottom: 8px;
  }

  .contentBlockTitle {
    color: $colour-black;
    display: block;
    padding: 2px 0 0;
    font-size: ($font-size-caption - 2);
    line-height: $line-height-body-medium;
    font-weight: bold;

    &__offer {
      color: $colour-offers;
    }

    &__contentSpots {
      font-size: ($font-size-caption);
      font-weight: 600;
    }
  }

  &__multi {
    flex: 1 0 $minColumnWidth;
  }

  &__large {
    text-align: center;
  }

  &:not(:last-of-type) {
    margin-bottom: spacing(2);
  }

  &__compact {
    display: flex;
    flex-direction: row;
    max-height: 68px;
    margin: 0 0 20px;
    height: 68px;
    min-height: 68px;

    &:not(:last-of-type) {
      margin-right: 0 !important;
    }
  }

  &__contentSpots {
    display: flex;
    flex-direction: row;
    height: 58px;
    margin: 0;
    max-height: 58px;
    min-height: 58px;
    margin-bottom: 10px !important;
    border-bottom: 1px solid $colour-grey-15;
  }

  &__contentSpotsXL {
    display: flex;
    flex-direction: row;
    height: 96px;
    margin: 0;
    max-height: 106px;
    min-height: 106px;
    margin-bottom: 10px !important;
    border-bottom: 1px solid $colour-grey-15;
  }

  .contentBlockTextContentSpots {
    height: 48px;
    margin-left: 10px;
    flex-grow: 1;
  }

  .contentBlockTextContentSpotsXL {
    margin-top: 25px;
    margin-left: 10px;
    flex-grow: 1;
  }
}
