@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.container {
  position: fixed;
  transition: height 0.3s, padding 0.3s;
  width: 100vw;
  max-width: 100vw;
  height: 0;
  z-index: 100;
  overflow: hidden;
  visibility: hidden;

  &__visible {
    height: 79px;
    visibility: visible;
  }

  @include breakpoint(medium) {
    position: relative;
  }

  @include breakpoint(1200) {
    display: none;
    visibility: hidden;
  }
}

.bannerContainer {
  background-color: $colour-taupe-40;
  line-height: 1.25;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.bannerContainer > *:not(:last-child) {
  margin-right: 20px;
}

.leftContent {
  flex: 3;
  display: flex;
  align-items: center;

  img {
    max-height: 55px;
  }
}

.leftContent > *:not(:last-child) {
  margin-right: 10px;
}

.rightContent {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}

.rightContent > *:not(:last-child) {
  margin-left: 20px;
}

.viewBtn {
  font-size: 16px;
  padding: 8px;
  background-color: $colour-grey-15;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.closeBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.subTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
