@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

$fixedHeaderHeight: 72px;
$fixedHeaderHeight_Small: 83px;

$fixedHeaderHeight_BU: 66px;
$fixedHeaderHeight_Small_BU: 77px;

.mobileMenu {
  position: fixed;
  top: $fixedHeaderHeight_BU;
  right: 0;
  bottom: 0;
  z-index: 100;

  height: calc(100% - #{$fixedHeaderHeight_BU});
  width: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  padding-bottom: $fixedHeaderHeight_BU;

  background-color: $colour-white;
  margin: 0;

  max-width: none;

  @include breakpoint-between(600, 1024) {
    width: 62.5%;
    max-width: none;
  }
  @include breakpoint(small) {
    top: $fixedHeaderHeight_Small_BU;
  }

  &__defaultBreakpoint {
    @include breakpoint(small) {
      top: $fixedHeaderHeight_Small_BU;
      height: calc(100% - #{$fixedHeaderHeight_Small_BU});
      max-width: 504px;
    }
  }

  &__persistSearch {
    top: 130px;
    height: calc(100% - 130px);

    @include breakpoint(small) {
      top: $fixedHeaderHeight_Small;
      height: calc(100% - #{$fixedHeaderHeight_Small});
    }
  }

  &__app_banner {
    top: 145px;
    height: calc(100% - 145px);

    @include breakpoint(small) {
      top: 156px;
      height: calc(100% - 156px);
    }
  }

  &__text,
  &__button {
    padding-left: spacing(2);
    padding-right: spacing(2);
  }

  &__name {
    padding: spacing(3) spacing(2) spacing(1) spacing(2);
    margin-bottom: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    text-transform: none;
    letter-spacing: 0;
    @include display-as('heading-xs');

    &__mobileNavTest {
      padding: 13px 16px;
    }

    a {
      text-decoration: none;
      padding: 0;
    }
  }

  &__nestedHeading {
    margin: 0 spacing(2);
    padding: spacing(3) 0 spacing(1.5);
    border: none;
    padding-bottom: 4px;
    padding-top: spacing(5);
    @include display-as('heading-xs');
  }
}

.mobileMenuList {
  margin: 0 0 spacing(2) 0;
  padding: 0;
  display: block;
  align-items: left;

  &__nested {
    margin-bottom: 0;
  }
}

.contentBlocks {
  margin: spacing(4) spacing(2);
  overflow: hidden;

  &__container {
    display: flex;
    overflow: scroll;
  }

  &__block {
    &:not(:last-child) {
      margin-right: spacing(2);
    }
  }

  &__contentSpot {
    &:not(:last-child) {
      margin-right: 0;
    }
  }

  &__contentSpots {
    background: $colour-white;
    margin: spacing(2) 0;
    padding: spacing(2) 0 spacing(2) spacing(2);
  }

  &__heading {
    margin-bottom: spacing(1.5);
  }
}

@keyframes slideIn {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0%);
  }
}

$transform-duration: 0.5s;
$transform-easing: cubic-bezier(0, 0, 0, 1);
$transform-transition: transform $transform-duration $transform-easing;

.menu {
  transform: translate(0);
  transition: $transform-transition;
}

.subMenu {
  position: absolute;
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow: visible;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  transition: $transform-transition;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.categoryLevelMenu {
  transform: translate(100%);
}

.activeLevelRoot {
  transform: translate(0%);
}
.activeLevelFirst {
  transform: translate(-100%);
}
.activeLevelSecond {
  transform: translate(-200%);
}
.activeLevelThird {
  transform: translate(-300%);
}

.nestedMenuItem {
  list-style-type: none;
  margin: 0;

  &:last-child {
    margin-bottom: spacing(4);
  }
}

.brandsMenuButton {
  padding: 12px 16px 20px;
  margin-bottom: -32px;
  a {
    @include display-as('heading-xs');
    color: white;
    background: black;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    text-decoration: none;
  }
}

.feedbackMenuBottom {
  margin-top: auto;
}
