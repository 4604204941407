@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.feedbackMenu {
  padding: spacing(3) spacing(2) 0;

  h3 {
    @include display-as('heading-s');
    text-transform: none;
    padding: 0;
    margin-bottom: spacing(2);
  }

  background-color: $colour-spedan-green;
  border-top: none;
  padding-bottom: spacing(2);
  margin-top: spacing(4);

  h3,
  p {
    color: $colour-white;
  }
  a {
    line-height: 20px;
    padding: spacing() + 4 spacing(3);
    width: auto;
  }
}
